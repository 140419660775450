































































































































import SectionsFeatureData from '@/calendesk/models/SectionsFeatureData'
import CalendeskImageSelectV2 from '@/components/Tools/CalendeskImageSelectV2.vue'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import SelectedGalleryImage from '@/calendesk/models/SelectedGalleryImage'
import DummySectionImage from '@/calendesk/models/DummySectionImage'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import TipTap from '@/lib/calendesk-js-library/components/TipTap.vue'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
import CtaButtons from '@/components/Tools/CtaButtons/CtaButtons.vue'

export default mixins(DraftElement).extend({
  name: 'SectionFeature',
  components: { CtaButtons, TipTap, CalendeskImageSelectV2 },
  props: {
    feature: {
      type: SectionsFeatureData
    },
    images: {
      type: Array,
      default: () => Array<SectionImage>()
    },
    imageEnabled: {
      type: Boolean,
      default: false
    },
    htmlContent: {
      type: Boolean,
      default: false
    },
    hideSubtitle: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowingMenu: false,
      uuid: null as null | string,
      title: null as null | string,
      subtitle: null as null | string | undefined,
      description: null as null | string,
      imageUrl: null as string | null,
      buttons: [] as CtaButtonData[]
    }
  },
  watch: {
    feature () {
      this.reload()
    }
  },
  mounted () {
    this.reload()
    this.imageUrl = this.image ? this.image.getUrl() : null
  },
  computed: {
    image (): SectionImage | null {
      if (this.uuid && this.images) {
        const found = (this.images as Array<SectionImage>).find((image: SectionImage) => image.slug === this.uuid)

        if (found !== undefined) {
          return found as SectionImage
        }
      }

      return null
    }
  },
  methods: {
    reload () {
      this.uuid = this.feature.uuid
      this.title = this.feature.title
      this.subtitle = this.feature.subtitle
      this.description = this.feature.description
      this.buttons = this.feature.buttons
    },
    updateItem () {
      const data = new SectionsFeatureData(
        this.uuid as string,
        this.title as string,
        this.subtitle as string,
        this.description as string,
        this.buttons as CtaButtonData[]
      )

      this.$emit('change', data)
    },
    deleteItem () {
      this.$emit('delete', true)
    },
    duplicateItem () {
      this.$emit('duplicate', true)
    },
    imageChangeHandle (selectedGalleryImage: SelectedGalleryImage | null) {
      if (selectedGalleryImage) {
        if (this.image) {
          this.imageUrl = selectedGalleryImage.getUrl()
          this.$emit('image-change', selectedGalleryImage, this.image)
        } else {
          const sectionImage = SectionImage.createFromDummyImage(
            new DummySectionImage(this.uuid as string, selectedGalleryImage.name, SectionImageType.INTERNAL)
          )
          this.imageUrl = selectedGalleryImage.getUrl()
          this.$emit('image-change', selectedGalleryImage, sectionImage)
        }

        this.updateItem()
      } else {
        this.imageUrl = null
        this.$emit('image-change', null, this.image)
      }
    },
    updateButtons (buttons: CtaButtonData[]) {
      this.buttons = buttons
      this.updateItem()
    }
  }
})
