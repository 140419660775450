import Image from "@tiptap/extension-image";

export const ImageResize = Image.extend({
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      style: {
        default: "width: 100%; height: auto;",
        parseHTML: (element) => {
          const width = element.getAttribute("width");
          return width
            ? `width: ${width}px; height: auto;`
            : `${element.style.cssText}`;
        },
      },
      title: {
        default: null,
      },
      loading: {
        default: null,
      },
      srcset: {
        default: null,
      },
      sizes: {
        default: null,
      },
      crossorigin: {
        default: null,
      },
      usemap: {
        default: null,
      },
      ismap: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      referrerpolicy: {
        default: null,
      },
      longdesc: {
        default: null,
      },
      decoding: {
        default: null,
      },
      class: {
        default: null,
      },
      id: {
        default: null,
      },
      name: {
        default: null,
      },
      draggable: {
        default: true,
      },
      tabindex: {
        default: null,
      },
      "aria-label": {
        default: null,
      },
      "aria-labelledby": {
        default: null,
      },
      "aria-describedby": {
        default: null,
      },
    };
  },
  addNodeView() {
    return ({ node, editor, getPos }) => {
      const {
        view,
        options: { editable },
      } = editor;
      const { style } = node.attrs;
      const $container = document.createElement("div");
      const $img = document.createElement("img");

      const dispatchNodeView = () => {
        if (typeof getPos === "function") {
          const newAttrs = {
            ...node.attrs,
            style: `${$img.style.cssText}`,
          };
          view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
        }
      };

      $container.setAttribute("style", `${style}; user-select: text;`);
      $container.setAttribute("class", `tiptap-image-container`);
      $container.setAttribute("contenteditable", "true");
      $container.appendChild($img);

      Object.entries(node.attrs).forEach(([key, value]) => {
        if (value === undefined || value === null) return;
        $img.setAttribute(key, value);
      });

      // Merge the max-width with the existing styles
      const currentStyle = $img.getAttribute("style") || "";
      $img.setAttribute(
        "style",
        `${currentStyle}; max-width: 100%; height: auto;`,
      );

      if (!editable) return { dom: $img };

      const dotsPosition = [
        "top: -4px; left: -4px; cursor: nwse-resize;",
        "top: -4px; right: -4px; cursor: nesw-resize;",
        "bottom: -4px; left: -4px; cursor: nesw-resize;",
        "bottom: -4px; right: -4px; cursor: nwse-resize;",
      ];

      let isResizing = false;
      let startX, startWidth;

      $container.addEventListener("click", () => {
        //remove remaining dots
        if ($container.childElementCount > 3) {
          for (let i = 0; i < 4; i++) {
            $container.removeChild($container.lastChild);
          }
        }

        $container.setAttribute(
          "style",
          `position: relative; border: 1px dashed #7F0CCD; ${style} cursor: pointer; line-height: 0;`,
        );

        Array.from({ length: 4 }, (_, index) => {
          const $dot = document.createElement("div");
          $dot.setAttribute(
            "style",
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #7F0CCD; border-radius: 50%; ${dotsPosition[index]}`,
          );

          $dot.addEventListener("mousedown", (e) => {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;

            const onMouseMove = (e) => {
              if (!isResizing) return;

              // Get the maximum allowable width (TipTap wrapper width)
              const maxWidth =
                document.querySelector(".tip-tap-editor").offsetWidth;

              const deltaX =
                index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;

              let newWidth = startWidth + deltaX;

              // Ensure the new width doesn't exceed the maximum/minimum width
              if (newWidth < 20) {
                newWidth = 20;
              } else if (newWidth > maxWidth) {
                newWidth = maxWidth;
              }

              $container.style.width = newWidth + "px";

              $img.style.width = newWidth + "px";
            };

            const onMouseUp = () => {
              if (isResizing) {
                isResizing = false;
              }
              dispatchNodeView();

              document.removeEventListener("mousemove", onMouseMove);
              document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
          });
          $container.appendChild($dot);
        });
      });

      document.addEventListener("click", (e) => {
        const $target = e.target;
        const isClickInside = $container.contains($target);

        if (!isClickInside) {
          const containerStyle = $container.getAttribute("style");
          const newStyle = containerStyle?.replace(
            "border: 1px dashed #7F0CCD;",
            "",
          );
          $container.setAttribute("style", newStyle);

          if ($container.childElementCount > 3) {
            for (let i = 0; i < 4; i++) {
              $container.removeChild($container.lastChild);
            }
          }
        }
      });

      return {
        dom: $container,
      };
    };
  },
});
