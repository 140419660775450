import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { RoundType } from '@/calendesk/models/RoundType'

export default () => [
  {
    text: trans('wb_round_zero_title'),
    value: RoundType.ZERO
  },
  {
    text: trans('wb_round_sm_title'),
    value: RoundType.SM
  },
  {
    text: trans('wb_round_md_title'),
    value: RoundType.MD
  },
  {
    text: trans('wb_round_lg_title'),
    value: RoundType.LG
  },
  {
    text: trans('wb_round_xl_title'),
    value: RoundType.XL
  },
  {
    text: trans('wb_round_pill_title'),
    value: RoundType.PILL
  },
  {
    text: trans('wb_round_circle_title'),
    value: RoundType.CIRCLE
  }
]
