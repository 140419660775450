export default class DynamicFormData {
  public type: number
  public label: string | null
  public description: string | null
  public required: boolean
  public equal: string | number | null
  public services: Array<number> | null
  public radioOptions: Array<number | string> | null

  constructor (type: number, label: string | null, description: string | null, required: boolean, services: Array<number> | null, radioOptions: Array<number | string> | null, equal: string | number | any) {
    this.type = type
    this.label = label
    this.description = description
    this.required = required
    this.services = services
    this.equal = equal
    this.radioOptions = radioOptions
  }
}
