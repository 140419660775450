import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'

export default () => [
  {
    text: trans('wb_gallery_style_1_title'),
    value: GalleryStyleType.STYLE_1
  },
  {
    text: trans('wb_gallery_style_2_title'),
    value: GalleryStyleType.STYLE_2
  },
  {
    text: trans('wb_gallery_style_3_title'),
    value: GalleryStyleType.STYLE_3
  }
]
