import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import DraftActions from '@/builder/mixins/DraftActions'
import { mapActions, mapGetters } from 'vuex'

export default mixins(DraftElement, DraftActions).extend({
  computed: {
    ...mapGetters({
      products: 'simpleStore/getProducts'
    })
  },
  methods: {
    ...mapActions({
      fetchProducts: 'simpleStore/fetch'
    })
  }
})
