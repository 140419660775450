

























































import { mapGetters, mapActions } from 'vuex'
import sharedDialogTypes from '@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import { modeTypes } from '@/lib/calendesk-js-library/components/assets/assetsTypes'
import { plainToClass } from 'class-transformer'
import SelectedGalleryImage from '@/calendesk/models/SelectedGalleryImage'
import Vue from 'vue'
import Dialog from '@/calendesk/models/Dialog'

export default Vue.extend({
  name: 'CalendeskImageSelectV2',
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    avatarMode: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Image'
    },
    selectImageButtonText: {
      type: String,
      default: 'Select'
    },
    displayRemoveImageButton: {
      type: Boolean,
      default: true
    },
    removeImageButtonText: {
      type: String,
      default: 'Remove'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      listenerId: Math.floor(Math.random() * 10000)
    }
  },
  computed: {
    ...mapGetters({
      getSelected: 'assets/getSelected',
      getConfirmSelected: 'assets/getConfirmSelected',
      getSelectType: 'assets/getSelectType'
    })
  },
  watch: {
    getConfirmSelected () {
      if (!this.getConfirmSelected || this.getSelectType !== this.listenerId) return
      this.$emit('change', plainToClass(SelectedGalleryImage, this.getSelected))
      this.resetSelected()
    }
  },
  methods: {
    ...mapActions({
      setMode: 'assets/setMode',
      setSelectType: 'assets/setSelectType',
      openDialog: 'dialog/open',
      resetSelected: 'assets/resetSelected'
    }),
    openAssetsDialog () {
      this.setMode(modeTypes.SINGLE)
      this.setSelectType(this.listenerId)
      this.openDialog(new Dialog(true, sharedDialogTypes.ASSETS_DIALOG, this.$trans('files'), dialogSize.FULL_SCREEN))
    },
    removeImageFunction () {
      this.$emit('change', null)
    }
  }
})
