<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="reducedSelected"
    :search-input.sync="searchText"
    no-filter
    outlined
    :items="items"
    :label="
      !selected || (multiple && selected.length === 0)
        ? label || $trans('service')
        : label || $trans('service')
    "
    :multiple="multiple"
    hide-details="auto"
    :item-text="serviceName"
    :dense="dense"
    :rules="rules"
    :loading="isLoading"
    :disabled="disabled"
    :no-data-text="$trans('nothing_here')"
    :placeholder="$trans('autocomplete_hint')"
    return-object
    :clearable="clearable"
    :attach="'#' + uniqueId"
    class="cd-fake-outer"
    @input="onInputChange"
    @blur="handleInputBlur"
  >
    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="data.index < maxItemsInSelectField">
          <v-chip
            v-if="chips || smallChips"
            :disabled="disabled"
            :small="smallChips"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <v-auto-complete-chips-tooltip>
              <service-card-details :service="data.item" />
            </v-auto-complete-chips-tooltip>
            <v-avatar
              v-if="data.item.default_image"
              :size="32"
              left
              class="mr-2"
            >
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-truncate">{{ serviceName(data.item) }}</span>
          </v-chip>
          <template v-else>
            <v-avatar
              v-if="data.item.default_image"
              :size="32"
              left
              class="mr-2"
            >
              <v-img :src="$helpers.getAvatarSrc(data.item)" />
            </v-avatar>
            <span class="text-truncate">{{ serviceName(data.item) }}</span>
          </template>
        </template>
        <div
          v-if="multiple && data.index === maxItemsInSelectField"
          class="grey--text text-caption"
        >
          (+{{ selected.length - maxItemsInSelectField }} {{ $trans("more") }})
        </div>
      </div>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        v-bind="attrs"
        :class="{
          'primary--text v-list-item--active v-list-item--highlighted':
            isItemSelected(item),
        }"
        v-on="on"
        @click.stop="toggleItem(item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex align-center pointer my-2">
              <v-avatar v-if="item.default_image" :size="32" left class="mr-2">
                <v-img :src="$helpers.getAvatarSrc(item)" />
              </v-avatar>
              <span class="text-break cd-v-select-line-height text-wrap">
                {{ serviceName(item) }}
              </span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #append-outer>
      <div :id="uniqueId"></div>
    </template>
  </v-autocomplete>
</template>

<script>
import duration from "@/lib/calendesk-js-library/filters/duration";
import { hasOnlyRole } from "@/lib/calendesk-js-library/tools/helpers";
import ServiceCardDetails from "@/lib/calendesk-js-library/components/service/ServiceCardDetails.vue";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import { mapGetters } from "vuex";
import { debounce } from "debounce";
import VAutoCompleteChipsTooltip from "@/lib/calendesk-js-library/components/VAutoCompleteChipsTooltip.vue";

export default {
  name: "ServicesField",
  components: { VAutoCompleteChipsTooltip, ServiceCardDetails },
  mixins: [sharedFieldActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, Object, Number, String],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    showOnlyGroupServices: {
      type: Boolean,
      default: false,
    },
    showOnlyOneToOneServices: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    returnIds: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    // If multiple, the service types will be ignored.
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    keepMenuOpenOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      selected: null,
      reducedSelected: null,
      showTypeMenu: false,
      searchText: null,
    };
  },
  computed: {
    ...mapGetters({
      categoryWithServices: "category/categoryWithServices",
    }),
  },
  watch: {
    showOnlyGroupServices() {
      this.reloadItems();
    },
    showOnlyOneToOneServices() {
      this.reloadItems();
    },
    categoryWithServices() {
      this.reloadItems();
    },
    value() {
      this.assignValue();
    },
    selected(selected) {
      if (selected) {
        if (this.multiple) {
          if (selected.length > 0) {
            this.reducedSelected = selected.slice(
              0,
              this.maxItemsInSelectField + 1
            );
          } else {
            this.reducedSelected = [];
          }
        } else {
          this.reducedSelected = selected;
        }
      } else {
        this.reducedSelected = this.multiple ? [] : null;
      }
    },
    searchText() {
      this.reloadItemsDebounced();
    },
  },
  created() {
    this.assignValue();
    this.reloadItems();
  },
  methods: {
    assignValue() {
      let assign = this.value;

      if (this.returnIds && this.value) {
        assign = this.multiple ? [] : null;
        this.categoryWithServices.forEach((category) => {
          if (category.services) {
            category.services.forEach((service) => {
              if (this.multiple) {
                if (this.value.includes(service.id)) {
                  assign.push(service);
                }
              } else if (this.value === service.id) {
                assign = service;
              }
            });
          }
        });
      }

      this.selected = assign;
    },
    reloadItemsDebounced: debounce(function () {
      this.reloadItems();
    }, 300),
    reloadItems() {
      const userHasOnlyEmployeeBasicRole = hasOnlyRole("employee_basic");

      const supportedServiceIds =
        this.loggedUser &&
        this.loggedUser.employee &&
        this.loggedUser.employee.services
          ? this.loggedUser.employee.services.map((service) => service.id)
          : [];

      let items = [];

      // This is a case when a service is already selected, but it's not supported by an employee anymore.
      // For example, booking case.
      if (
        !this.multiple &&
        this.selected &&
        !supportedServiceIds.includes(this.selected.id)
      ) {
        supportedServiceIds.push(this.selected.id);
      }

      const searchText = this.searchText ? this.searchText.toLowerCase() : null;

      if (this.categoryWithServices && this.categoryWithServices.length > 0) {
        this.categoryWithServices.forEach((category) => {
          const prepareToPush = [];
          let hasMatchingServices = false;

          if (category.services) {
            category.services.forEach((service) => {
              let skipPushingService = !!searchText;

              if (searchText) {
                const serviceName = this.serviceName(service).toLowerCase();
                if (serviceName.includes(searchText)) {
                  skipPushingService = false;
                }
              }

              // Make sure the user can use this service.
              if (
                !userHasOnlyEmployeeBasicRole ||
                (userHasOnlyEmployeeBasicRole &&
                  (supportedServiceIds.includes(service.id) ||
                    (this.service && this.service.id === service.id)))
              ) {
                if (
                  (this.showOnlyGroupServices && service.max_people > 1) ||
                  (this.showOnlyOneToOneServices && service.max_people === 1) ||
                  (!this.showOnlyGroupServices &&
                    !this.showOnlyOneToOneServices)
                ) {
                  if (
                    !this.multiple &&
                    service.types &&
                    service.types.length > 0
                  ) {
                    service.types.forEach((type) => {
                      let skipPushingType = !!searchText;
                      const serviceTypeName = this.$helpers.getServiceName(
                        service,
                        type
                      );

                      if (
                        searchText &&
                        serviceTypeName.toLowerCase().includes(searchText)
                      ) {
                        skipPushingType = false;
                      }

                      if (!skipPushingType) {
                        hasMatchingServices = true;
                        prepareToPush.push({ ...service, selectedType: type });
                      }
                    });
                  } else if (!skipPushingService) {
                    hasMatchingServices = true;
                    prepareToPush.push(service);
                  }
                }
              }
            });
          }

          if (hasMatchingServices && prepareToPush.length > 0) {
            prepareToPush.unshift({ divider: true });
            prepareToPush.unshift({ header: category.name, id: category.id });
            items.push(...prepareToPush);
          }
        });
      }

      this.mergeResponseWithSelectedItems(items);
    },
    mergeResponseWithSelectedItems(items) {
      let result = items;

      if (this.searchText) {
        result =
          result && result.length > 0
            ? result
            : [
                {
                  header: this.$trans("nothing_found_here"),
                  id: "selected",
                },
              ];

        result = [...result];

        if (this.multiple) {
          if (this.selected && this.selected.length > 0) {
            result.push({ divider: true });
            result.push(...this.selected);
          }
        } else {
          if (this.selected) {
            result.push(this.selected);
          }
        }
      }

      this.items = result;
    },
    informAboutChanges() {
      if (!this.keepMenuOpenOnSelect) {
        this.$refs.autocomplete.isFocused = false;
      }

      if (this.selected) {
        let result = this.$helpers.cloneObject(this.selected);

        if (this.returnIds) {
          result = this.multiple
            ? this.selected.map((u) => u.id)
            : this.selected.id;
        }

        this.$emit("input", result);
        this.$emit("change", result);
      } else {
        this.$emit("input", null);
        this.$emit("change", null);
      }
    },
    renderDuration(value) {
      return duration(value.duration);
    },
    serviceName(item) {
      // :item-text in v-autocomplete passes two arguments
      // we need to make sure we send only an item.
      return this.$helpers.getServiceName(
        item,
        null,
        false,
        false,
        false,
        this.multiple
      );
    },
    findServiceIndex(serviceList, item) {
      return serviceList.findIndex(
        (service) =>
          service.id === item.id &&
          (this.multiple ||
            (service.serviceType &&
              item.serviceType &&
              service.serviceType.id === item.serviceType.id))
      );
    },
    areItemsEqual(item1, item2) {
      return (
        item1.id === item2.id &&
        (this.multiple ||
          (item1.serviceType &&
            item2.serviceType &&
            item1.serviceType.id === item2.serviceType.id))
      );
    },
    removeChip(item) {
      if (this.multiple) {
        const index = this.findServiceIndex(this.selected, item);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
      } else {
        this.selected = null;
      }

      this.informAboutChanges();
    },
    toggleItem(item) {
      if (this.selected) {
        if (this.multiple) {
          const index = this.findServiceIndex(this.selected, item);

          if (index > -1) {
            this.selected.splice(index, 1);
          } else {
            this.selected.push(item);
          }
        } else {
          this.selected = this.areItemsEqual(this.selected, item) ? null : item;
        }
      } else {
        this.selected = this.multiple ? [item] : item;
      }

      this.informAboutChanges();
    },
    isItemSelected(item) {
      if (this.selected) {
        if (this.multiple) {
          const index = this.findServiceIndex(this.selected, item);
          return index > -1;
        } else {
          return this.areItemsEqual(this.selected, item);
        }
      }

      return false;
    },
    onInputChange() {
      if (!this.reducedSelected || this.reducedSelected.length === 0) {
        this.selected = this.multiple ? [] : null;
        this.informAboutChanges();
      }
    },
    handleInputBlur() {
      // Because we load all the services (items) to the input, when leaving the input, we don't want to keep the search value.
      // For some reason, v-autocomplete doesn't reset this value, even though we use .sync option.
      setTimeout(() => {
        if (this.$refs.autocomplete && !this.$refs.autocomplete.isMenuActive) {
          this.searchText = null;
        }
      }, 500);
    },
  },
};
</script>
