var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[(_vm.label)?_c('v-card-title',{staticClass:"text-break"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.subtitle)?_c('v-card-subtitle',{staticClass:"text-break"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"pa-2"},[(_vm.editor)?_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('bold') ? 'primary' : null}},[_vm._v("$format-bold")])],1)]}}],null,false,1936511279)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_bold")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('italic') ? 'primary' : null}},[_vm._v("$format-italic")])],1)]}}],null,false,1353122320)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_italic")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('underline') ? 'primary' : null}},[_vm._v("$format-underline")])],1)]}}],null,false,3834449548)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_underline")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleStrike().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('strike') ? 'primary' : null}},[_vm._v("$format-strikethrough")])],1)]}}],null,false,752033027)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_strike")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setParagraph().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('paragraph') ? 'primary' : null}},[_vm._v("$format-paragraph")])],1)]}}],null,false,1774964532)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_paragraph")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 1 })
                      ? 'primary'
                      : null}},[_vm._v("$format-header-1")])],1)]}}],null,false,976888133)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H1"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 2 })
                      ? 'primary'
                      : null}},[_vm._v("$format-header-2")])],1)]}}],null,false,2287296230)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H2"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 3 })
                      ? 'primary'
                      : null}},[_vm._v("$format-header-3")])],1)]}}],null,false,3643029639)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H3"))])]),(_vm.showAllHeadlineButtons)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 4 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 4 })
                        ? 'primary'
                        : null}},[_vm._v("$format-header-4")])],1)]}}],null,false,148914848)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H4"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 5 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 5 })
                        ? 'primary'
                        : null}},[_vm._v("$format-header-5")])],1)]}}],null,false,3801365185)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H5"))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleHeading({ level: 6 }).run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('heading', { level: 6 })
                        ? 'primary'
                        : null}},[_vm._v("$format-header-6")])],1)]}}],null,false,3426338786)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_heading") + " H6"))])])]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('bulletList') ? 'primary' : null}},[_vm._v("$format-list-bulleted")])],1)]}}],null,false,1058928738)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_bullet_list")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().toggleCodeBlock().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('codeBlock') ? 'primary' : null}},[_vm._v("$code-tags")])],1)]}}],null,false,4070675589)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_code_block")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setHorizontalRule().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$minus")])],1)]}}],null,false,606245715)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_horizontal_rule")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setHardBreak().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$format-page-break")])],1)]}}],null,false,3477648288)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_hard_break")))])]),_c('c-color-picker',{attrs:{"icon":"$format-color-highlight","tooltip-label":_vm.$trans('html_editor_tooltip_background_color')},model:{value:(_vm.highlightedColor),callback:function ($$v) {_vm.highlightedColor=$$v},expression:"highlightedColor"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().unsetHighlight().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('highlight') ? 'primary' : ''}},[_vm._v("$format-color-highlight-cancel")])],1)]}}],null,false,3251272913)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_delete_background_color")))])]),_c('c-color-picker',{attrs:{"icon":"$format-color-text","tooltip-label":_vm.$trans('html_editor_tooltip_font_color')},model:{value:(_vm.fontColor),callback:function ($$v) {_vm.fontColor=$$v},expression:"fontColor"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('left').run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('left') ? 'primary' : null}},[_vm._v("$format-align-left")])],1)]}}],null,false,3895979121)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_align_left")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('right').run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('right') ? 'primary' : null}},[_vm._v("$format-align-right")])],1)]}}],null,false,1766065258)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_align_right")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('center').run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('center') ? 'primary' : null}},[_vm._v("$format-align-center")])],1)]}}],null,false,1491234145)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_align_center")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().setTextAlign('justify').run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('justify') ? 'primary' : null}},[_vm._v("$format-align-justify")])],1)]}}],null,false,650839332)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_align_justify")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.openAssetsDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$image")])],1)]}}],null,false,1817496209)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_add_mage")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.setLink}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('link') ? 'primary' : null}},[_vm._v("$link")])],1)]}}],null,false,1829518672)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_link")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.editor.isActive('link')},on:{"click":function($event){_vm.editor.chain().focus().unsetLink().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('link') ? 'primary' : null}},[_vm._v("$link-off")])],1)]}}],null,false,2717039666)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_unlink")))])]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var tooltipOn = ref.on;
                        var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',tooltipAttrs,false),tooltipOn),[_vm._v(" $format-size ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_font_size")))])])]}}],null,false,2370108184),model:{value:(_vm.fontMenu),callback:function ($$v) {_vm.fontMenu=$$v},expression:"fontMenu"}},[_c('v-container',{staticClass:"white"},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":[
                      8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96 ],"value":_vm.fontSize},on:{"change":function($event){_vm.fontSize = $event}}})],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.setHTML}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$paste")])],1)]}}],null,false,4114629061)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_set_html")))])])],2)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$undo")])],1)]}}],null,false,4227076813)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_undo")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$redo")])],1)]}}],null,false,783753613)},[_c('span',[_vm._v(_vm._s(_vm.$trans("html_editor_tooltip_redo")))])])],1)],1)],1):_vm._e(),_c('editor-content',{staticClass:"tip-tap-editor custom-html-section",class:_vm.customClass ? ( _obj = {}, _obj[_vm.customClass] = true, _obj ) : {},attrs:{"editor":_vm.editor}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }