import CtaButtonData from '@/calendesk/models/CtaButtonData'
import { Type } from 'class-transformer'

export default class SectionsFeatureData {
  public uuid: string
  public title: string
  public subtitle?: string | null
  public description: string

  @Type(() => CtaButtonData)
  public buttons: CtaButtonData[]

  constructor (uuid: string, title: string, subtitle: string | null, description: string, buttons: CtaButtonData[] = []) {
    this.uuid = uuid
    this.title = title
    this.subtitle = subtitle
    this.description = description
    this.buttons = buttons
  }
}
