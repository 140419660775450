import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      uniqueId: this.generateRandomString(12),
    };
  },
  computed: {
    maxItemsInSelectField() {
      const maxCountsForBreakpoints = {
        600: 1,
        800: 2,
        1180: 5,
        1500: 5,
        1820: 5,
        2100: 6,
        2200: 8,
        2300: 10,
        2400: 12,
        2500: 14,
      };
      const { width } = this.$vuetify.breakpoint;

      // Retrieve the smallest and largest breakpoints
      const sortedBreakpoints = Object.keys(maxCountsForBreakpoints)
        .map(Number)
        .sort((a, b) => a - b);
      const minBreakpoint = sortedBreakpoints[0];
      const maxBreakpoint = sortedBreakpoints[sortedBreakpoints.length - 1];

      // Return 1 if width is less than the smallest breakpoint
      if (width < minBreakpoint) {
        return maxCountsForBreakpoints[minBreakpoint];
      }

      // Return the maxCount for the largest breakpoint if width exceeds it
      if (width > maxBreakpoint) {
        return maxCountsForBreakpoints[maxBreakpoint];
      }

      // Find the closest breakpoint that is less than or equal to the width
      const closestBreakpoint = Math.max(
        ...sortedBreakpoints.filter((bp) => width >= bp)
      );

      // Return the maxCount for that breakpoint
      return maxCountsForBreakpoints[closestBreakpoint];
    },
  },
};
