<template>
  <div>
    <div
      class="d-flex align-center text-break"
      :class="{
        'justify-start': justifyLeft,
        'justify-end': justifyRight,
        'justify-center': !justifyLeft && !justifyRight,
        pointer: clickable,
      }"
      @click.stop="
        clickable ? $helpers.goToService(service, pushTransition) : null
      "
    >
      <v-avatar
        v-if="avatar"
        left
        class="mr-2 align-self-start"
        :size="dense ? 32 : 42"
      >
        <v-img :src="avatar" />
      </v-avatar>
      <span>{{ name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    serviceType: {
      type: Object,
      required: false,
      default: null,
    },
    getServiceTypeFromServiceObject: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideDuration: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    justifyLeft: {
      type: Boolean,
      default: false,
    },
    justifyRight: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    pushTransition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name() {
      return this.$helpers.getServiceName(
        this.service,
        this.getServiceTypeFromServiceObject
          ? this.service.serviceType
          : this.serviceType,
        this.hideDetails,
        this.hideDuration
      );
    },
    avatar() {
      if (this.service && this.service.default_image) {
        return this.$helpers.getAvatarSrc(this.service);
      }

      return null;
    },
  },
};
</script>
