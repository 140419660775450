import { Paragraph } from "@tiptap/extension-paragraph";
import { mergeAttributes } from "@tiptap/core";

export const CdParagraph = Paragraph.extend({
  renderHTML({ node, HTMLAttributes }) {
    const isEmpty = node.content.size === 0;

    const extraClass = isEmpty
      ? {
          class: `cd-paragraph`,
        }
      : {};
    return [
      "p",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, extraClass),
      0,
    ];
  },
});
