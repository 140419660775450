











































































import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import { modeTypes } from '@/lib/calendesk-js-library/components/assets/assetsTypes'
import sharedDialogTypes from '@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import Dialog from '@/calendesk/models/Dialog'
import { plainToClass } from 'class-transformer'
import SelectedGalleryImage from '@/calendesk/models/SelectedGalleryImage'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'

export default Vue.extend({
  name: 'GalleryInputV2',
  props: {
    items: {
      type: Array,
      default: () => Array<SectionImage>()
    },
    slugPrefix: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'Gallery'
    }
  },
  data () {
    return {
      items_: Array<SectionImage>(),
      changingAtIndex: null as number | null,
      listenerId: Math.floor(Math.random() * 10000)
    }
  },
  computed: {
    ...mapGetters({
      getSelected: 'assets/getSelected',
      getConfirmSelected: 'assets/getConfirmSelected',
      getSelectType: 'assets/getSelectType'
    })
  },
  watch: {
    items () {
      this.reload()
    },
    getConfirmSelected () {
      if (!this.getConfirmSelected || this.getSelectType !== this.listenerId) return
      const selectedGalleryImage = plainToClass(SelectedGalleryImage, this.getSelected as SelectedGalleryImage)

      const sectionImage = plainToClass(SectionImage, {
        id: null,
        slug: this.changingAtIndex !== null ? this.items_[this.changingAtIndex].slug : this.getNextSlug(),
        type: SectionImageType.PORTFOLIO,
        section_id: null,
        user_image_id: selectedGalleryImage.defaultImage.userImageId,
        name: selectedGalleryImage.defaultImage.name
      })

      if (this.changingAtIndex !== null) {
        this.items_[this.changingAtIndex].name = sectionImage.name
        this.changingAtIndex = null
      } else {
        this.items_.push(sectionImage)
      }

      this.$emit('create', selectedGalleryImage, sectionImage)
      this.resetSelected()
    }
  },
  created () {
    this.reload()
  },
  methods: {
    ...mapActions({
      setMode: 'assets/setMode',
      setSelectType: 'assets/setSelectType',
      openDialog: 'dialog/open',
      resetSelected: 'assets/resetSelected',
      openConfirmDialog: 'confirmDialog/open',
      closeConfirmDialog: 'confirmDialog/close',
      setCommonDialogLoader: 'loader/setCommonDialogLoader'
    }),
    getNextSlug (): string {
      if (this.items_.length > 0) {
        return this.slugPrefix + String(Number(this.items_[this.items_.length - 1].slug.replace(this.slugPrefix, '')) + 1)
      }

      return this.slugPrefix + '1'
    },
    reload () {
      const itemsCopy = cloneClassObject(this.items as Array<SectionImage>)
      this.items_ = itemsCopy.sort((item1: SectionImage, item2: SectionImage) => {
        const index1 = Number(item1.slug.replace(this.slugPrefix, ''))
        const index2 = Number(item2.slug.replace(this.slugPrefix, ''))

        return index1 - index2
      })
    },
    changeImageAction (index: number) {
      this.changingAtIndex = index
      this.openAssetDialog()
    },
    addImageAction () {
      this.changingAtIndex = null
      this.openAssetDialog()
    },
    openAssetDialog () {
      this.setMode(modeTypes.SINGLE)
      this.setSelectType(this.listenerId)
      const dialog = new Dialog(true, sharedDialogTypes.ASSETS_DIALOG, this.$trans('files'), dialogSize.FULL_SCREEN)
      this.openDialog(dialog)
    },
    removeImageHandle (index: number) {
      this.$emit('remove', this.items_[index])
      this.items_.splice(index, 1)
    }
  }
})
