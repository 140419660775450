
























































import Vue from 'vue'
import SectionsFeatureData from '@/calendesk/models/SectionsFeatureData'
import { v4 as uuidv4 } from 'uuid'
import SectionFeature from '@/components/Tools/SectionFeatures/components/SectionFeature.vue'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import SelectedGalleryImage from '@/calendesk/models/SelectedGalleryImage'
import { arrayMoveImmutable } from '@/lib/calendesk-js-library/tools/helpers'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'

export default Vue.extend({
  components: { SectionFeature },
  props: {
    features: {
      type: Array,
      default: () => Array<SectionsFeatureData>()
    },
    images: {
      type: Array,
      default: () => Array<SectionImage>()
    },
    imageEnabled: {
      type: Boolean,
      default: false
    },
    htmlContent: {
      type: Boolean,
      default: false
    },
    hideSubtitle: {
      type: Boolean,
      default: false
    },
    hideImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowingMenu: false,
      items: Array<SectionsFeatureData>()
    }
  },
  created () {
    this.items = this.features as Array<SectionsFeatureData>
  },
  methods: {
    updateItem (event: SectionsFeatureData, index: number) {
      this.items[index] = event
      this.update()
    },
    deleteItem (event: Event, index: number) {
      this.items.splice(index, 1)
      this.update()
    },
    duplicateItem (event: Event, index: number) {
      const item = cloneClassObject(this.items[index])
      this.items.push(new SectionsFeatureData(
        uuidv4(),
        item.title,
        item.subtitle ? item.subtitle : this.$trans('wb_subtitle'),
        item.description,
        item.buttons))
      this.update()
    },
    addItem () {
      this.items.push(new SectionsFeatureData(
        uuidv4(),
        this.$trans('wb_title'),
        this.hideSubtitle ? null : this.$trans('wb_subtitle'),
        this.$trans('wb_text')))
      this.update()
    },
    update () {
      this.$emit('change', this.items)
    },
    internalImageChangeHandle (selectedGalleryImage: SelectedGalleryImage | null, sectionImage: SectionImage): void {
      this.$emit('image-change', selectedGalleryImage, sectionImage)
    },
    moveUp (index: number) {
      this.items = arrayMoveImmutable(this.items, index, index - 1)
      this.update()
    },
    moveDown (index: number) {
      this.items = arrayMoveImmutable(this.items, index, index + 1)
      this.update()
    }
  }
})
