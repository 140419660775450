import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'

export default class DummySectionImage {
  public slug: string
  public name: string
  public type!: SectionImageType

  constructor (slug: string, name: string, type: SectionImageType) {
    this.slug = slug
    this.name = name
    this.type = type
  }
}
