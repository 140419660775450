
























import CtaButton from '@/components/Tools/CtaButtons/components/CtaButton.vue'
import Vue from 'vue'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
export default Vue.extend({
  components: { CtaButton },
  props: {
    buttons: {
      type: Array,
      default: () => Array<CtaButtonData>()
    }
  },
  data () {
    return {
      items: Array<CtaButtonData>()
    }
  },
  created () {
    this.items = this.buttons as Array<CtaButtonData>
  },
  methods: {
    updateButton (event: CtaButtonData, index: number) {
      this.items[index] = event
      this.update()
    },
    deleteButton (event: Event, index: number) {
      this.items.splice(index, 1)
      this.update()
    },
    addButton () {
      this.items.push(
        new CtaButtonData(
          this.$trans('wb_new_button'),
          null,
          null,
          false,
          true,
          true,
          'large',
          false,
          null,
          0,
          null,
          null,
          null,
          null,
          null,
          true,
          false,
          true,
          true,
          true,
          true,
          true
        ))
      this.update()
    },
    update () {
      this.$emit('change', this.items)
    }
  }
})
