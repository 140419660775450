import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import { isRgbColor, rgbToHex } from "@/lib/calendesk-js-library/tools/helpers";

export const FontColor = Extension.create({
  name: "fontColor",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontColor: {
            default: null,
            parseHTML: (element) => {
              let color = element.style.color.replace(/['"]+/g, "");
              if (isRgbColor(color)) {
                color = rgbToHex(color);
              }

              return color;
            },
            renderHTML: (attributes) => {
              if (!attributes.fontColor) {
                return {};
              }

              return {
                style: `color: ${attributes.fontColor}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontColor:
        (fontColor) =>
        ({ chain }) =>
          chain().setMark("textStyle", { fontColor }).run(),
      unsetFontColor:
        () =>
        ({ chain }) =>
          chain()
            .setMark("textStyle", { fontColor: null })
            .removeEmptyTextStyle()
            .run(),
    };
  },
});
