import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { AlignType } from '@/calendesk/models/AlignType'

export default () => [
  {
    text: trans('wb_position_bottom_right_title'),
    value: AlignType.BOTTOM_RIGHT
  },
  {
    text: trans('wb_position_bottom_left_title'),
    value: AlignType.BOTTOM_LEFT
  },
  {
    text: trans('wb_position_bottom_center_title'),
    value: AlignType.BOTTOM_CENTER
  }
]
