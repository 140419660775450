import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default class StaticPage {
  public static readonly BOOKINGS = '1c95a138-1800-4596-b6ab-abc5638c6921';
  public static readonly USER_SUBSCRIPTIONS = '23ccbbea-8ff1-47f9-98c9-c95253503ec0';
  public static readonly PAYMENTS = '08f22a76-41fb-4f6f-ba99-2ebd8e02de75';
  public static readonly MY_ACCOUNT = '920f1a9d-7b67-4285-8b23-5ec48f206ab9';

  public uuid: string;
  public name: string;

  constructor (uuid: string, name: string) {
    this.uuid = uuid
    this.name = name
  }

  static getAllStaticPages (): StaticPage[] {
    return [
      new StaticPage(StaticPage.BOOKINGS, trans('client_website_bookings_tab')),
      new StaticPage(StaticPage.USER_SUBSCRIPTIONS, trans('client_website_user_subscriptions_tab')),
      new StaticPage(StaticPage.PAYMENTS, trans('client_website_payments_tab')),
      new StaticPage(StaticPage.MY_ACCOUNT, trans('client_website_my_account_tab'))
    ]
  }
}
