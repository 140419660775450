import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'

export default () => [
  {
    text: trans('wb_button_size_type_x_small_title'),
    value: ButtonSizeType.X_SMALL
  },
  {
    text: trans('wb_button_size_type_small_title'),
    value: ButtonSizeType.SMALL
  },
  {
    text: trans('wb_button_size_type_medium_title'),
    value: ButtonSizeType.MEDIUM
  },
  {
    text: trans('wb_button_size_type_large_title'),
    value: ButtonSizeType.LARGE
  },
  {
    text: trans('wb_button_size_type_x_large_title'),
    value: ButtonSizeType.X_LARGE
  }
]
