













































import Vue from 'vue'

export default Vue.extend({
  name: 'CTimePicker',
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    }
  },
  data () {
    return {
      time: null as string | null,
      menu: false,
      modal: false
    }
  },
  watch: {
    time (val: string) {
      if (this.time !== this.value) {
        this.$emit('updated', val)
      }
    }
  },
  created () {
    this.time = this.value
  }
})
