

































































































































































































































































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import CtaButtonData from '@/calendesk/models/CtaButtonData'
import Page from '@/calendesk/models/DTO/Response/Page'
import { CtaButtonType, DynamicFormType } from '@/calendesk/models/BuilderTypes'
import SimpleStoreProduct from '@/calendesk/models/DTO/Response/SimpleStoreProduct'
import Service from '@/calendesk/models/DTO/Response/Service'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'
import ServicesField from '@/lib/calendesk-js-library/components/ServicesField.vue'
import { ServiceInServiceField } from '@/calendesk/models/ServiceInServiceField'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import DynamicFormData from '@/calendesk/models/DynamicFormData'
import DynamicForm from '@/components/Tools/DynamicForm/DynamicForm.vue'
import { plainToClass } from 'class-transformer'
import StaticPage from '@/calendesk/models/StaticPage'

export default Vue.extend({
  name: 'CtaButton',
  components: { DynamicForm, ServicesField },
  props: {
    button: {
      type: CtaButtonData
    },
    allowDelete: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bookingFormType: DynamicFormType.BOOKING,
      buttonTypes: CtaButtonType,
      selectedPage: null as null | undefined | Page | StaticPage,
      selectedProduct: null as null | SimpleStoreProduct,
      selectedSubscription: null as null | Subscription,
      selectedService: null as null | Service,
      isShowingMenu: false,
      pageId: null as null | string,
      pageUrl: null as null | string,
      openInNewTab: false,
      title: null as null | string,
      outlined: false,
      rounded: false,
      size: null as null | string,
      isFunctional: false,
      width: null as number | null,
      elevation: null as number | null,
      sspId: null as number | null,
      serviceId: null as number | null,
      serviceTypeId: null as number | null,
      subscriptionId: null as number | null,
      bookingFormData: [] as DynamicFormData[],
      selectInitialLocation: true,
      showBillingData: true,
      requireBillingData: false,
      showNameAndSurnameFields: true,
      showBookedSlots: true,
      showPhoneNumberField: true,
      phoneNumberRequired: true,
      type: CtaButtonType.REDIRECT as number | null,
      showPageUrl: false
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      products: 'simpleStore/getProducts',
      subscriptions: 'subscription/getSubscriptions',
      services: 'service/getServices',
      categoryAndServices: 'category/categoryWithServices'
    }),
    getBookingFormData (): DynamicFormData[] {
      if (this.bookingFormData) {
        return this.bookingFormData
      }

      return []
    },
    draftPagesWithStaticPages (): (Page | StaticPage)[] {
      const staticPages = StaticPage.getAllStaticPages()

      return [...this.draftPages, ...staticPages]
    }
  },
  watch: {
    selectedPage (page?: Page | StaticPage) {
      if (page && this.pageId !== page.uuid) {
        if (!this.title) {
          this.title = page.name
        }

        this.pageId = page.uuid
        this.pageUrl = null
        this.updateButton()
      }
    },
    showPageUrl (val) {
      if (!val) {
        this.pageUrl = null
        this.updateButton()
      }
    },
    openInNewTab () {
      this.updateButton()
    },
    showBillingData () {
      this.updateButton()
    },
    selectInitialLocation () {
      this.updateButton()
    },
    showNameAndSurnameFields () {
      this.updateButton()
    },
    showBookedSlots () {
      this.updateButton()
    },
    showPhoneNumberField () {
      this.updateButton()
    },
    phoneNumberRequired () {
      this.updateButton()
    },
    requireBillingData () {
      this.updateButton()
    },
    selectedProduct (product?: SimpleStoreProduct) {
      if (product && this.sspId !== product.id) {
        this.sspId = product.id
        this.updateButton()
      }
    },
    selectedService (service?: ServiceInServiceField) {
      if (service && ((this.serviceId !== service.id) ||
        (service.selectedType && this.serviceTypeId !== service.selectedType.id))) {
        this.serviceId = service.id

        if (service.selectedType) {
          this.serviceTypeId = service.selectedType.id
        } else {
          this.serviceTypeId = null
        }

        this.updateButton()
      }
    },
    selectedSubscription (subscription?: Subscription) {
      if (subscription && this.subscriptionId !== subscription.id) {
        this.subscriptionId = subscription.id
        this.updateButton()
      }
    },
    button () {
      this.reload()
    }
  },
  created () {
    this.reload()
  },
  methods: {
    reload () {
      this.pageId = this.button.pageId
      this.pageUrl = this.button.pageUrl ?? null
      this.openInNewTab = this.button.openInNewTab ?? false
      this.showPageUrl = !!this.pageUrl
      this.title = this.button.title
      this.outlined = this.button.outlined
      this.rounded = this.button.rounded
      this.size = this.button.size
      this.isFunctional = this.button.isFunctional
      this.width = this.button.width
      this.elevation = this.button.elevation
      this.sspId = this.button.sspId
      this.serviceId = this.button.serviceId
      this.serviceTypeId = this.button.serviceTypeId
      this.subscriptionId = this.button.subscriptionId
      this.bookingFormData = this.button.bookingFormData ? plainToClass(DynamicFormData, this.button.bookingFormData as Array<DynamicFormData>) : []
      this.showBillingData = typeof this.button.showBillingData === 'undefined' ? true : this.button.showBillingData
      this.selectInitialLocation = typeof this.button.selectInitialLocation === 'undefined' ? true : this.button.selectInitialLocation
      this.showNameAndSurnameFields = typeof this.button.showNameAndSurnameFields === 'undefined' ? true : this.button.showNameAndSurnameFields
      this.showBookedSlots = typeof this.button.showBookedSlots === 'undefined' ? false : this.button.showBookedSlots
      this.showPhoneNumberField = typeof this.button.showPhoneNumberField === 'undefined' ? true : this.button.showPhoneNumberField
      this.phoneNumberRequired = typeof this.button.phoneNumberRequired === 'undefined' ? true : this.button.phoneNumberRequired
      this.requireBillingData = this.button.requireBillingData

      if (this.pageId || this.pageUrl) {
        this.type = this.buttonTypes.REDIRECT

        if (this.pageId) {
          this.selectedPage = this.draftPagesWithStaticPages.find((page: Page | StaticPage) => page.uuid === this.pageId)
        }
      } else if (this.sspId) {
        this.type = this.buttonTypes.SIMPLE_STORE_PRODUCT
        this.selectedProduct = this.products.find((product: SimpleStoreProduct) => product.id === this.sspId)
      } else if (this.serviceId) {
        this.type = this.buttonTypes.BOOKING

        const selectedService = this.services.find((service: Service) => service.id === this.serviceId)

        if (this.serviceTypeId && selectedService && selectedService.types) {
          const foundType = selectedService.types.find((serviceType: ServiceType) => serviceType.id === this.serviceTypeId)
          this.selectedService = { ...selectedService, selectedType: foundType }
        } else {
          this.selectedService = selectedService
        }
      } else if (this.subscriptionId) {
        this.type = this.buttonTypes.SUBSCRIPTION
        this.selectedSubscription = this.subscriptions.find((subscription: Subscription) => subscription.id === this.subscriptionId)
      }
    },
    updateType (type: number) {
      if (type === this.buttonTypes.REDIRECT) {
        this.sspId = null
        this.selectedProduct = null

        this.serviceId = null
        this.serviceTypeId = null
        this.selectedService = null

        this.subscriptionId = null
        this.selectedSubscription = null
        this.bookingFormData = []
      } else if (type === this.buttonTypes.SIMPLE_STORE_PRODUCT) {
        this.pageId = null
        this.pageUrl = null
        this.showPageUrl = false
        this.selectedPage = null

        this.serviceId = null
        this.serviceTypeId = null
        this.selectedService = null

        this.subscriptionId = null
        this.selectedSubscription = null
        this.bookingFormData = []
      } else if (type === this.buttonTypes.BOOKING) {
        this.pageId = null
        this.pageUrl = null
        this.showPageUrl = false
        this.selectedPage = null

        this.sspId = null
        this.selectedProduct = null

        this.subscriptionId = null
        this.selectedSubscription = null
      } else if (type === this.buttonTypes.SUBSCRIPTION) {
        this.pageId = null
        this.pageUrl = null
        this.showPageUrl = false
        this.selectedPage = null

        this.serviceId = null
        this.serviceTypeId = null
        this.selectedService = null

        this.sspId = null
        this.selectedProduct = null
        this.bookingFormData = []
      }
    },
    updateButton () {
      const data = new CtaButtonData(
        this.title as string,
        this.pageId,
        this.pageUrl,
        this.openInNewTab,
        this.outlined,
        this.rounded,
        this.size as string,
        this.isFunctional,
        this.width,
        this.elevation,
        this.sspId,
        this.serviceId,
        this.serviceTypeId,
        this.subscriptionId,
        this.bookingFormData,
        this.showBillingData,
        this.requireBillingData,
        this.showNameAndSurnameFields,
        this.showPhoneNumberField,
        this.phoneNumberRequired,
        this.selectInitialLocation,
        this.showBookedSlots
      )

      this.$emit('change', data)
    },
    deleteButton () {
      this.$emit('delete', true)
    },
    getName (item: Subscription | Page | StaticPage | SimpleStoreProduct): string {
      if (item instanceof Page || item instanceof Subscription || item instanceof SimpleStoreProduct) {
        return `(ID: ${item.id}) ${item.name}`
      }

      return `${item.name}`
    },
    updateBookingFormData (data: any) {
      this.bookingFormData = data
      this.updateButton()
    }
  }
})
