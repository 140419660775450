<template>
  <v-card outlined>
    <v-card-title v-if="label" class="text-break">
      {{ label }}
    </v-card-title>
    <v-card-subtitle v-if="subtitle" class="text-break">
      {{ subtitle }}
    </v-card-subtitle>
    <div class="pa-2">
      <v-container v-if="editor" class="pa-0 ma-0" fluid>
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 ma-0">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleBold().run()"
                >
                  <v-icon :color="editor.isActive('bold') ? 'primary' : null"
                    >$format-bold</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_bold") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleItalic().run()"
                >
                  <v-icon :color="editor.isActive('italic') ? 'primary' : null"
                    >$format-italic</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_italic") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleUnderline().run()"
                >
                  <v-icon
                    :color="editor.isActive('underline') ? 'primary' : null"
                    >$format-underline</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_underline") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleStrike().run()"
                >
                  <v-icon :color="editor.isActive('strike') ? 'primary' : null"
                    >$format-strikethrough</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_strike") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setParagraph().run()"
                >
                  <v-icon
                    :color="editor.isActive('paragraph') ? 'primary' : null"
                    >$format-paragraph</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_paragraph") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 1 })
                        ? 'primary'
                        : null
                    "
                    >$format-header-1</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_heading") + " H1" }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 2 })
                        ? 'primary'
                        : null
                    "
                    >$format-header-2</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_heading") + " H2" }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  "
                >
                  <v-icon
                    :color="
                      editor.isActive('heading', { level: 3 })
                        ? 'primary'
                        : null
                    "
                    >$format-header-3</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_heading") + " H3" }}</span>
            </v-tooltip>

            <template v-if="showAllHeadlineButtons">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 4 }).run()
                    "
                  >
                    <v-icon
                      :color="
                        editor.isActive('heading', { level: 4 })
                          ? 'primary'
                          : null
                      "
                      >$format-header-4</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $trans("html_editor_tooltip_heading") + " H4" }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 5 }).run()
                    "
                  >
                    <v-icon
                      :color="
                        editor.isActive('heading', { level: 5 })
                          ? 'primary'
                          : null
                      "
                      >$format-header-5</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $trans("html_editor_tooltip_heading") + " H5" }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      editor.chain().focus().toggleHeading({ level: 6 }).run()
                    "
                  >
                    <v-icon
                      :color="
                        editor.isActive('heading', { level: 6 })
                          ? 'primary'
                          : null
                      "
                      >$format-header-6</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ $trans("html_editor_tooltip_heading") + " H6" }}</span>
              </v-tooltip>
            </template>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleBulletList().run()"
                >
                  <v-icon
                    :color="editor.isActive('bulletList') ? 'primary' : null"
                    >$format-list-bulleted</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_bullet_list") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().toggleCodeBlock().run()"
                >
                  <v-icon
                    :color="editor.isActive('codeBlock') ? 'primary' : null"
                    >$code-tags</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_code_block") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setHorizontalRule().run()"
                >
                  <v-icon>$minus</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_horizontal_rule") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setHardBreak().run()"
                >
                  <v-icon>$format-page-break</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_hard_break") }}</span>
            </v-tooltip>

            <c-color-picker
              v-model="highlightedColor"
              icon="$format-color-highlight"
              :tooltip-label="$trans('html_editor_tooltip_background_color')"
            />

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().unsetHighlight().run()"
                >
                  <v-icon :color="editor.isActive('highlight') ? 'primary' : ''"
                    >$format-color-highlight-cancel</v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                $trans("html_editor_tooltip_delete_background_color")
              }}</span>
            </v-tooltip>

            <c-color-picker
              v-model="fontColor"
              icon="$format-color-text"
              :tooltip-label="$trans('html_editor_tooltip_font_color')"
            />

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setTextAlign('left').run()"
                >
                  <v-icon :color="editor.isActive('left') ? 'primary' : null"
                    >$format-align-left</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_align_left") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setTextAlign('right').run()"
                >
                  <v-icon :color="editor.isActive('right') ? 'primary' : null"
                    >$format-align-right</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_align_right") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setTextAlign('center').run()"
                >
                  <v-icon :color="editor.isActive('center') ? 'primary' : null"
                    >$format-align-center</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_align_center") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().setTextAlign('justify').run()"
                >
                  <v-icon :color="editor.isActive('justify') ? 'primary' : null"
                    >$format-align-justify</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_align_justify") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="openAssetsDialog">
                  <v-icon>$image</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_add_mage") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="setLink">
                  <v-icon :color="editor.isActive('link') ? 'primary' : null"
                    >$link</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_link") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!editor.isActive('link')"
                  v-on="on"
                  @click="editor.chain().focus().unsetLink().run()"
                >
                  <v-icon :color="editor.isActive('link') ? 'primary' : null"
                    >$link-off</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_unlink") }}</span>
            </v-tooltip>

            <v-menu
              v-model="fontMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template #activator="{ on, attrs }">
                <v-tooltip bottom>
                  <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-bind="tooltipAttrs" v-on="tooltipOn">
                        $format-size
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $trans("html_editor_tooltip_font_size") }}</span>
                </v-tooltip>
              </template>
              <v-container class="white">
                <v-row>
                  <v-col>
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96,
                      ]"
                      :value="fontSize"
                      @change="fontSize = $event"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-menu>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="setHTML">
                  <v-icon>$paste</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_set_html") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" class="pa-0 ma-0">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().undo().run()"
                >
                  <v-icon>$undo</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_undo") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="editor.chain().focus().redo().run()"
                >
                  <v-icon>$redo</v-icon>
                </v-btn>
              </template>
              <span>{{ $trans("html_editor_tooltip_redo") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
      <editor-content
        class="tip-tap-editor custom-html-section"
        :class="customClass ? { [customClass]: true } : {}"
        :editor="editor"
      />
    </div>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { TextAlign } from "@tiptap/extension-text-align";
import { Highlight } from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import { Placeholder } from "@tiptap/extension-placeholder";
import { CdHeadline } from "@/lib/calendesk-js-library/components/TipTap/CdHeadline";
import { FontSize } from "@/lib/calendesk-js-library/components/TipTap/FontSize";
import { FontColor } from "@/lib/calendesk-js-library/components/TipTap/FontColor";

import CColorPicker from "@/lib/calendesk-js-library/components/CColorPicker";
import { CdParagraph } from "@/lib/calendesk-js-library/components/TipTap/CdParagraph";
import { modeTypes } from "@/lib/calendesk-js-library/components/assets/assetsTypes";
import sharedDialogTypes from "@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { mapActions, mapGetters, mapMutations } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { ImageResize } from "@/lib/calendesk-js-library/components/TipTap/ImageResize";

export default {
  components: {
    CColorPicker,
    EditorContent,
  },
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    showAllHeadlineButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      fontMenu: false,
      imageSizeDialog: false,
      imageWidth: "",
      imageHeight: "",
      selectedImagePos: null,
      selectedImageSrc: "",
      instanceId: this.generateRandomString(8),
    };
  },
  computed: {
    ...mapGetters({
      getSelected: "assets/getSelected",
      getInstanceIdForEvents: "assets/getSelectType",
      assetDialogImageSelected: "assets/getConfirmSelected",
    }),
    highlightedColor: {
      get() {
        const attr = this.editor.getAttributes("highlight");
        if (attr.color) {
          return attr.color;
        }
        return "#000000";
      },
      set(newValue) {
        if (newValue) {
          this.editor.chain().focus().setHighlight({ color: newValue }).run();
        }
      },
    },
    fontColor: {
      get() {
        const attr = this.editor.getAttributes("textStyle");
        if (attr.fontColor) {
          return attr.fontColor;
        }

        return "#000000";
      },
      set(newValue) {
        if (newValue) {
          this.editor.chain().focus().setFontColor(newValue).run();
        }
      },
    },
    fontSize: {
      get() {
        const attr = this.editor.getAttributes("textStyle");
        if (attr.fontSize) {
          return parseInt(attr.fontSize.replace("px", "").replace("pt", ""));
        }

        return null;
      },
      set(size) {
        this.editor.chain().focus().setFontSize(`${size}px`).run();
        this.fontMenu = false;
      },
    },
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    },
    assetDialogImageSelected() {
      if (this.getInstanceIdForEvents === this.instanceId) {
        if (
          !this.assetDialogImageSelected ||
          !this.getSelected ||
          !this.getSelected.default_image ||
          !this.getSelected.default_image.name
        ) {
          return;
        }

        this.editor
          .chain()
          .focus()
          .setImage({
            src: this.$helpers.getAvatarSrc(this.getSelected),
            alt: this.getSelected.description ?? this.getSelected.name,
          })
          .run();
        this.resetSelected();
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      vueComponent: this,
      content: this.value,
      extensions: [
        StarterKit.configure({
          history: true,
          heading: false,
          paragraph: false,
        }),
        ImageResize.configure({
          inline: true,
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
          alignments: ["left", "right", "center", "justify"],
        }),
        Highlight.configure({
          multicolor: true,
        }),
        TextStyle,
        FontColor,
        Underline,
        Link.configure({
          HTMLAttributes: {
            // Allow search engines to follow links (remove nofollow)
            rel: "noopener noreferrer",
            target: "_blank",
          },
        }),
        CdHeadline,
        CdParagraph,
        FontSize,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      onUpdate: () => {
        let content = this.editor.getHTML();
        const json = this.editor.getJSON().content;

        // eslint-disable-next-line no-prototype-builtins
        if (
          Array.isArray(json) &&
          json.length === 1 &&
          !json[0].hasOwnProperty("content")
        ) {
          content = null;
        }

        this.$emit("input", content);
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    ...mapMutations({
      setMode: "assets/SET_MODE",
      setSelectType: "assets/SET_SELECT_TYPE",
    }),
    ...mapActions({
      resetSelected: "assets/resetSelected",
    }),
    toggleResize() {
      this.editor.chain().focus().toggleResizable().run();
    },
    setLink() {
      const url = window.prompt(this.$trans("enter_url"));

      if (url) {
        this.editor.chain().focus().setLink({ href: url }).run();
      }
    },
    openAssetsDialog() {
      this.setMode(modeTypes.SINGLE);
      this.setSelectType(this.instanceId);
      this.openDialog({
        type: sharedDialogTypes.ASSETS_DIALOG,
        title: this.$trans("files"),
        size: dialogSize.FULL_SCREEN,
      });
    },
    setHTML() {
      const html = window.prompt("HTML");
      this.editor.chain().focus().insertContent(html).run();
    },
    pasteContent(text) {
      const transaction = this.editor.state.tr.insertText(text);
      this.editor.view.dispatch(transaction);
      const content = this.editor.getHTML();
      this.$emit("input", content);
    },
  },
};
</script>

<style lang="scss">
.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  margin-top: 20px;
}

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  font-size: 16px;
  color: #6a6a6a;
  pointer-events: none;
  height: 0;
}

.tip-tap-editor {
  div:has(img) {
    display: inline-block !important;
  }

  max-width: 100%;
  overflow: hidden;
  position: relative;
}
</style>
