import { Expose, Type } from 'class-transformer'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import store from '@/store'

export default class SelectedGalleryImage {
  @Expose({ name: 'default_image' })
  @Type(() => DefaultImage)

  public defaultImage!: DefaultImage;

  public description?: string | null;
  public id!: number;
  public name!: string;

  public getUrl (): string | null {
    if (this.defaultImage && this.defaultImage.name && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.defaultImage.name
    }

    return null
  }
}
