















import DraftActions from '@/builder/mixins/DraftActions'
import mixins from 'vue-typed-mixins'
import { mapGetters } from 'vuex'
import Page from '@/calendesk/models/DTO/Response/Page'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
export default mixins(DraftActions).extend({
  name: 'PagesSingleSelect',
  props: {
    label: String,
    pageUuid: String
  },
  data () {
    return {
      selectedPage: null as Page | null
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages'
    })
  },
  watch: {
    pageUuid () {
      this.reloadPage()
    }
  },
  mounted () {
    this.reloadPage()
  },
  methods: {
    reloadPage () {
      const page = this.draftPages.find((page: Page) => page.uuid === this.pageUuid)

      if (page) {
        this.selectedPage = cloneClassObject(page)
      }
    }
  }
})
