<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        class="pointer"
        v-on="on"
      >
        &nbsp;
      </div>
    </template>
    <slot />
  </v-tooltip>
</template>
<script>
// This is somewhat of a workaround. We were unable to implement the correct behavior of v-tooltip in the v-autocomplete component.
// The position of v-menu was incorrect after multiple items were selected.
// Also, having v-tooltip directly was causing UI issues (such as the close button being displaced, etc.)
// This component should implement the changes and resolve these issues.
export default {};
</script>
