var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"autocomplete",staticClass:"cd-fake-outer",attrs:{"search-input":_vm.searchText,"no-filter":"","outlined":"","items":_vm.items,"label":!_vm.selected || (_vm.multiple && _vm.selected.length === 0)
      ? _vm.label || _vm.$trans('service')
      : _vm.label || _vm.$trans('service'),"multiple":_vm.multiple,"hide-details":"auto","item-text":_vm.serviceName,"dense":_vm.dense,"rules":_vm.rules,"loading":_vm.isLoading,"disabled":_vm.disabled,"no-data-text":_vm.$trans('nothing_here'),"placeholder":_vm.$trans('autocomplete_hint'),"return-object":"","clearable":_vm.clearable,"attach":'#' + _vm.uniqueId},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event},"input":_vm.onInputChange,"blur":_vm.handleInputBlur},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('div',{staticClass:"d-flex align-center pointer",staticStyle:{"max-width":"99%"}},[(data.index < _vm.maxItemsInSelectField)?[(_vm.chips || _vm.smallChips)?_c('v-chip',{attrs:{"disabled":_vm.disabled,"small":_vm.smallChips,"close":_vm.deletableChips},on:{"click:close":function($event){return _vm.removeChip(data.item)}}},[_c('v-auto-complete-chips-tooltip',[_c('service-card-details',{attrs:{"service":data.item}})],1),(data.item.default_image)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":32,"left":""}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(data.item)}})],1):_vm._e(),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.serviceName(data.item)))])],1):[(data.item.default_image)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":32,"left":""}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(data.item)}})],1):_vm._e(),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.serviceName(data.item)))])]]:_vm._e(),(_vm.multiple && data.index === _vm.maxItemsInSelectField)?_c('div',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selected.length - _vm.maxItemsInSelectField)+" "+_vm._s(_vm.$trans("more"))+") ")]):_vm._e()],2)]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({class:{
        'primary--text v-list-item--active v-list-item--highlighted':
          _vm.isItemSelected(item),
      },on:{"click":function($event){$event.stopPropagation();return _vm.toggleItem(item)}}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-center pointer my-2"},[(item.default_image)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":32,"left":""}},[_c('v-img',{attrs:{"src":_vm.$helpers.getAvatarSrc(item)}})],1):_vm._e(),_c('span',{staticClass:"text-break cd-v-select-line-height text-wrap"},[_vm._v(" "+_vm._s(_vm.serviceName(item))+" ")])],1)])],1)],1)]}},{key:"append-outer",fn:function(){return [_c('div',{attrs:{"id":_vm.uniqueId}})]},proxy:true}]),model:{value:(_vm.reducedSelected),callback:function ($$v) {_vm.reducedSelected=$$v},expression:"reducedSelected"}})}
var staticRenderFns = []

export { render, staticRenderFns }