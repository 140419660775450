


























import Vue from 'vue'
import GdprFieldData from '../../../calendesk/models/GdprFieldData'
import GdprFieldElement from '@/components/Tools/GdprFields/GdprFieldElement.vue'
export default Vue.extend({
  components: { GdprFieldElement },
  props: {
    elements: {
      type: Array,
      default: () => Array<GdprFieldData>()
    }
  },
  data () {
    return {
      items: Array<GdprFieldData>()
    }
  },
  created () {
    this.items = this.elements as Array<GdprFieldData>
  },
  methods: {
    updateElement (event: GdprFieldData, index: number) {
      this.items[index] = event
      this.update()
    },
    deleteElement (event: Event, index: number) {
      this.items.splice(index, 1)
      this.update()
    },
    addElement () {
      this.items.push(
        new GdprFieldData(
          this.$trans('wb_dummy_gdpr_field_name'),
          this.$trans('wb_dummy_gdpr_field'),
          true))
      this.update()
    },
    update () {
      this.$emit('change', this.items)
    }
  }
})
