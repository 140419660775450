import { Heading } from "@tiptap/extension-heading";
import { mergeAttributes } from "@tiptap/core";

export const CdHeadline = Heading.extend({
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];

    return [
      `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: `text-h${level}`,
      }),
      0,
    ];
  },
});
