




































































import Vue from 'vue'
import GalleryItem from '@/calendesk/models/GalleryItem'

export default Vue.extend({
  name: 'CItemsGallery',
  props: {
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    hideAvatar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeItem (removeItem: GalleryItem): void {
      let result = Array<GalleryItem>()
      if (this.items && Array.isArray(this.items)) {
        result = this.items.filter((item: GalleryItem) => item.id !== removeItem.id)
      }
      this.$emit('remove', result)
    }
  }
})
