





















































import Vue from 'vue'
import DynamicFormElement from '@/components/Tools/DynamicForm/DynamicFormElement.vue'
import DynamicFormData from '@/calendesk/models/DynamicFormData'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import { DynamicFormType } from '@/calendesk/models/BuilderTypes'
import { arrayMoveImmutable } from '@/lib/calendesk-js-library/tools/helpers'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'

export default Vue.extend({
  components: { DynamicFormElement },
  props: {
    elements: {
      type: Array,
      default: () => Array<DynamicFormData>()
    },
    formType: {
      type: String,
      default: DynamicFormType.GENERAL
    },
    disallowSelectedServices: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      items: Array<DynamicFormData>()
    }
  },
  created () {
    this.items = this.elements as Array<DynamicFormData>
  },
  methods: {
    updateElement (event: DynamicFormData, index: number) {
      this.items[index] = event
      this.update()
    },
    deleteElement (event: Event, index: number) {
      this.items.splice(index, 1)
      this.update()
    },
    duplicateElement (event: Event, index: number) {
      const item = this.items[index] as DynamicFormData
      this.items.push(cloneClassObject(item))
      this.update()
    },
    addElement () {
      this.items.push(
        new DynamicFormData(
          BookingDynamicFormTypes.TEXT_AREA,
          null,
          `<p>${this.$trans('wb_booking_note')}</p>`,
          false,
          null,
          [],
          null))
      this.update()
    },
    update () {
      this.$emit('change', this.items)
    },
    moveUp (index: number) {
      this.items = arrayMoveImmutable(this.items, index, index - 1)
      this.update()
    },
    moveDown (index: number) {
      this.items = arrayMoveImmutable(this.items, index, index + 1)
      this.update()
    }
  }
})
