import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import { DraftConfiguration } from '@/calendesk/models/DraftConfiguration'
import GalleryItem from '@/calendesk/models/GalleryItem'
import { DraftConfigurationType } from '@/calendesk/models/BuilderTypes'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import Service from '@/calendesk/models/DTO/Response/Service'
import duration from '@/lib/calendesk-js-library/filters/duration'
import money from '@/lib/calendesk-js-library/filters/money'
import SidebarDetails from '@/calendesk/models/SidebarDetails'
import { SidebarDetailsType } from '@/builder/sidebar/sidebarDetails/SidebarDetailsType'
import DraftActions from '@/builder/mixins/DraftActions'
import { mapActions, mapGetters } from 'vuex'

export default mixins(DraftElement, DraftActions).extend({
  computed: {
    ...mapGetters({
      employees: 'employee/getEmployees',
      services: 'service/getServices'
    })
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'employee/fetch'
    }),
    removeItemsGalleryHandle (items: Array<GalleryItem>, key: string): void {
      this.updateConfigurationProperty(key, items, true)
    },
    editItemsGalleryHandle (config: DraftConfiguration): void {
      let sidebarDetails: SidebarDetails | undefined
      if (config.type === DraftConfigurationType.EMPLOYEES) {
        sidebarDetails = new SidebarDetails(true, SidebarDetailsType.EMPLOYEES_SELECT, { key: config.key })
      } else if (config.type === DraftConfigurationType.SERVICES) {
        sidebarDetails = new SidebarDetails(true, SidebarDetailsType.SERVICES_SELECT, { key: config.key })
      }
      if (sidebarDetails) {
        this.openSidebarDetails(sidebarDetails)
      }
    },
    getItemsForGallery (config: DraftConfiguration): Array<GalleryItem> {
      const result = Array<GalleryItem>()

      if (config.type === DraftConfigurationType.EMPLOYEES || config.type === DraftConfigurationType.SERVICES) {
        const prop: DraftConfigurationType.EMPLOYEES | DraftConfigurationType.SERVICES = config.type
        config.value.forEach((item: Record<string, any>) => {
          if (this[prop]) {
            const found = this[prop].find((obj: Employee | Service) => obj.id === item.id)
            if (found) {
              if (config.type === DraftConfigurationType.EMPLOYEES) {
                result.push(this.createEmployeeGalleryItem(found))
              } else if (config.type === DraftConfigurationType.SERVICES) {
                result.push(this.createServiceGalleryItem(found))
              }
            }
          }
        })
      }

      return result
    },
    createEmployeeGalleryItem (employee: Employee): GalleryItem {
      return {
        title: `(${this.$trans('id')}: ${employee.id}) ${employee.user.name} ${employee.user.surname}`,
        subtitle: employee.title,
        subtitle2: null,
        imageUrl: employee.getDefaultImageUrl(),
        id: employee.id
      }
    },
    createServiceGalleryItem (service: Service): GalleryItem {
      return {
        title: `(${this.$trans('id')}: ${service.id}) ${service.name}`,
        subtitle: service.category ? service.category.name : '',
        subtitle2: `${duration(service.duration)} / ${money(service.price)}`,
        imageUrl: service.getDefaultImageUrl(),
        id: service.id
      }
    }
  }
})
