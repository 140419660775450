import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { AlignType } from '@/calendesk/models/AlignType'

export default () => [
  {
    text: trans('wb_position_top_right_title'),
    value: AlignType.TOP_RIGHT
  },
  {
    text: trans('wb_position_top_left_title'),
    value: AlignType.TOP_LEFT
  }
]
