<template>
  <v-menu
    ref="datepickerMenu"
    v-model="datepickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="date"
        outlined
        :label="label"
        persistent-hint
        append-icon="$calendar"
        :disabled="disabled"
        readonly
        :rules="rules"
        hide-details="auto"
        :dense="dense"
        :clearable="clearable"
        :class="additionalClass"
        @click:append="datepickerMenu = true"
        @click:clear="$emit('input', null)"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      first-day-of-week="1"
      no-title
      :max="max"
      :min="min"
      @input="$emit('input', date)"
    >
      <v-spacer />
      <v-btn text color="primary" @click="$refs.datepickerMenu.save(date)">
        {{ $trans("ok") }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "CalendarInput",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    max: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    additionalClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      datepickerMenu: false,
      date: this.value,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.date = val;
      } else {
        this.date = null;
      }
    },
  },
};
</script>
